<template>
  <accounting-entry-form v-model="obItem" />
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AccountingentriesMixin from "@/modules/accountingentries/mixins/AccountingentriesMixin";
import AccountingEntryForm from "@/modules/accountingentries/components/AccountingEntryForm.vue";

@Component({
  components: {
    AccountingEntryForm,
  },
})
export default class AccountingentriesForm extends Mixins(
  AccountingentriesMixin
) {}
</script>
