<template>
  <v-form v-if="obModel" class="fill-height" @submit.prevent>
    <form-observer
      :loading="isLoading"
      lazy-validation
      @cancel="cancel"
      @invalid="onInvalid"
      @save="onSave"
      @valid="onValid"
    >
      <accounting-entry-position-table
        v-if="ready"
        :disabled-movement-type-list="accountMovementTypeList"
        :valid="valid"
        :value="obModel"
      />
    </form-observer>
  </v-form>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AccountingEntryPositionTable from "@/modules/accountingentries/components/AccountingEntryPositionTable.vue";
import AccountingentriesMixin from "@/modules/accountingentries/mixins/AccountingentriesMixin";
import { EventBus } from "@/services/event-bus";

@Component({
  components: { AccountingEntryPositionTable },
})
export default class AccountingEntryForm extends Mixins(
  AccountingentriesMixin
) {
  forceSave = true;
  valid = false;
  ready = false;

  get accountMovementTypeList() {
    return this.obCollection.map("accounting_movement_type_id") as number[];
  }

  async onMounted() {
    this.obCollection.clear();
    await this.obCollection.list();
    this.ready = true;
  }

  onValid() {
    this.valid = true;
  }

  onInvalid() {
    this.valid = false;
  }

  onSave() {
    EventBus.emit("validate.accounting.entry", this.save);
  }
}
</script>
